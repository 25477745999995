<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-row :style="{ height: '33vh', 'min-height': '250px' }" align="center">
      <v-col align="center">
        <v-img src="../assets/1lifelogo.png" max-width="300px"> </v-img>
      </v-col>
    </v-row>
    <v-row align="end" class="msaBlue" justify="center">
      <v-col v-if="!showResult" align="center" :cols="isMobile ? 10 : 4">
        <v-row align="center">
          <v-col
            ><span class="text-h4 white--text"
              >Forgot your password?</span
            ></v-col
          >
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Enter your username to reset your password"
              background-color="white"
              v-model="username"
              color="msaBlue"
              filled
              depressed
              hide-details
              :style="{ 'border-radius': '5px' }"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              class="green white--text"
              :disabled="buttonDisabled"
              @click="sendEmailRequest"
              block
            >
              Request Password Reset
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else align="center">
        <v-row align="center">
          <v-col
            ><v-alert
              type="success"
              v-html="resultMessage"
              max-width="650px"
            ></v-alert
          ></v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="msaBlue">
      <v-col align="center">
        <v-btn text color="white" @click="backToSignIn" small>
          Back to sign in
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'ForgotPasswordPage',
  data() {
    return {
      username: '',
      resultMessage: '',
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    buttonDisabled() {
      return this.username.length == 0;
    },
    showResult() {
      return this.resultMessage.length > 0;
    },
  },
  methods: {
    backToSignIn() {
      this.$router.push({ name: 'UserLogin' });
    },
    sendEmailRequest() {
      const url = 'send-password-reset-request?format=json';
      const params = {
        username: this.username,
        loaderText: 'Sending...',
      };
      this.$axios
        .post(url, params)
        .then((response) => {
          this.resultMessage = response.data;
        })
        .catch((error) => error);
    },
  },
};
</script>
